
.tabs{
  padding:0;
  border:none;
  position:fixed;
  left:0;
  top:45%;
  z-index:838383;

  li{
    clear:left;
    padding:0;
    margin:0;
  }
}



ul.tabs{
  width:56px;
  position:fixed;
  top:40%;
  left:0;
  padding:0 !important;
  margin:0 !important;
  background:none;
  z-index:833;
  border:none !important;

  li{
    float:left;
    display:block;
    padding:0;
    margin:0 0 3px 0 !important;
    background:none;
    border-radius:0px;
    border:1px solid color('grey') !important;
    border-left:none !important;
    background:#FFF;

    a{
      display:block;
      width:35px;
      height:35px;
      border:1px solid color('grey') !important;
      padding:0 !important;
      text-indent:-380px;
      overflow:hidden;
      border:none !important;
      background:none !important;
      border-radius:0px;

      &:hover{
        width:55px;
        padding-left:20px !important;
      };

      &#tab-weergeven, &#tab-view{
        background:url('../images/icons/tab-icons.jpg') no-repeat left top !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px top !important;
        };
      }

      &#tab-bewerken, &#tab-edit{
        background:url('../images/icons/tab-icons.jpg') no-repeat left -35px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -35px !important;
        };
      }

      &#tab-vertalen, &#tab-translate{
        background:url('../images/icons/tab-icons.jpg') no-repeat left -70px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -70px !important;
        };
      }

      &#tab-devel{
        background:url('../images/icons/tab-icons.jpg') no-repeat left -175px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -175px !important;
        };
      }

      &#tab-depubliceren, &#tab-publiceren, &#tab-unpublish {
        background:url('../images/icons/tab-icons.jpg') no-repeat left -208px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -208px !important;
        };
      }

      &#tab-toegangscontrole{
        background:url('../images/icons/tab-icons.jpg') no-repeat left -105px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -105px !important;
        };
      }

      &#tab-revisions, &#tab-revisies {
        background:url('../images/icons/tab-icons.jpg') no-repeat left -238px !important;

        &:hover{
          background:url('../images/icons/tab-icons.jpg') no-repeat -98px -238px !important;
        };
      }

    }
  }
}
label.block__title {
  color: #8e2d34;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include typeface(heading);
}
// DMS General settings: Footer Text
.block__dms_general_settings__footer_text_contact {
  float: left;
  width: calc(33.3333% - 70px - 4rem);
  margin-right: 3em;
  @include respond-to('l') {
    float: left;
    width: calc(33.3333% - 1rem);
    margin-top: 1.5rem;
    margin-right: 1.5rem;
    clear: left;
  }
  @include respond-to('xs') {
    float: none;
    width: auto;
    margin-right: 0;
    img {
      margin: 0 auto;
    }
  }
}
// DMS THEME: Logo footer
.block__dms_theme__logo_footer {
  float: left;
  width: 210px;
  margin-right: 3rem;
  img {
    display: block;
    width: 210px;
    height: 171px;
  }
  a,
  a:link,
  a:visited,
  a:active {
    display: block;
  }
  @include respond-to('l') {
    float: none;
    width: auto;
    margin-right: 0;
    img {
      width: 150px;
      height: 122px;
      margin: 0 auto;
    }
  }
}
// DMS THEME: Header
.block__dms_theme__header {
  background: #e7e5e5;
  .content {
    width: 100%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
  .header__image {
    position: relative;
    //height: 500px;
    height: 656px;
    background-size: cover;
    background-position: center center;
    @include respond-to('l') {
      height: 500px;
    }
    @include respond-to('m') {
      height: 300px;
    }
  }
  .logged-in & {
    top: -29px;
  }
}
.header__image__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}
// DMS THEME: CTA Bottom
.block__dms_theme__cta_bottom {
  background: color(corporate);
}

//DMS THEME: Reference header
.block__dms_theme__reference_header {
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    //height: 500px;
    height: 656px;
    background: #EEE;
    @include respond-to('l') {
      height: 500px;
    }
    @include respond-to('m') {
      height: 400px;
    }
  }
  .logged-in & {
    top: -29px;
  }
  .content {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
  .reference,
  a.reference {
    display: block;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .reference__content {
    position: relative;
    //height: 500px;
    height: 656px;
    background-size: cover;
    background-position: center center;
    @include respond-to('l') {
      height: 500px;
    }
    @include respond-to('m') {
      height: 400px;
    }
    .layout-center {
      position: relative;
      //height: 500px;
      height: 656px;
      overflow: hidden;
      @include respond-to('l') {
        height: 500px;
      }
      @include respond-to('m') {
        height: 400px;
      }
    }
    .reference__usp {
      position: absolute;
      left: 0;
      bottom: 3.5rem;
      list-style-type: none;
      margin: 0  0 0 -10px;
      padding: 0;
      color: #FFF;
      text-decoration: none;
      z-index: 2;
      max-width: calc(100% - 1em);
      &:hover,
      &:focus {
        color: #FFF;
        text-decoration: none;
      }
    }
    .reference__usp__item {
      position: relative;
      background: color(corporate);
      padding: .5em 1em;
      margin-top: .5em;
      font-size: 1.58125em;
      @include typeface(button);
      transform: skew(20deg);
      float: left;
      clear: both;
      .skewinvert {
        transform: skew(-20deg);
      }
    }
  }

  .reference__header {
    display: block;
    @extend %clearfix;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 0.875em;
    line-height: 1.25em;
  }

  a.reference__header,
  a.reference__header:link,
  a.reference__header:visited,
  a.reference__header:active {
    .reference__link {
      display: none;
      float: left;
      text-transform: uppercase;
      color: color(text);
      border-bottom: 2px solid color(corporate);
    }
    .reference__title {
      float: right;
      display: block;
      position: relative;
      color: color(text);
      text-decoration: underline;
      padding: 0 1em 0 0;
      &:hover,
      &:focus {
        color: color(text);
        text-decoration: none;
      }
      .fa {
        position: absolute;
        right: 0;
        top: .1em;
      }
    }

  }

  .slick-dots {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    bottom: calc(38px + 1rem);
    text-align: right;
    width: 100%;
    margin: 0 auto;
    max-width: 1040px;
    padding-left: 20px;
    padding-right: 20px;

  }
  .slick-dots li,
  .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }


  // Video
  .reference__content--video {
    overflow: hidden;
    margin-bottom: 33px;
    .layout-center {
      position: inherit;
    }
    .layout-center {
      position: absolute;
      left: 0;
      bottom: 3.5rem;
      list-style-type: none;
    }
    .reference__usp {
    }
  }
  .reference__usp--wrapper {
    display: block;
    width: 100%;
    max-width: 1040px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    margin: 0 auto;
  }
  .reference__header__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
    }
  }
}



// Alheembouw updates
.block__dms_theme__updates {
  .rows {
    @extend %clearfix;
    list-style-type: none;
    margin: 0 0 -2% 0;
    padding: 0;
  }
  .row {
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n + 1) {
      clear: left;
    }
    @include respond-to('l') {
      &:nth-of-type(n) {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
        clear: none;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        clear: left;
      }
    }
    @include respond-to('xs') {
      &:nth-of-type(n) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}


// Alheembouw updates map
.block__dms_references__references_map {
  .dms-references-map-form-map {
    padding: 0;
    margin: 0;
    border: none;
  }
  .form-actions {
    text-align: center;
  }
  input[type='submit'] {
    display: inline-block;
    background: #FFF url('../images/icons/icon--map.png') no-repeat left center;
    border: none;
    padding: .1em 0 .1em 1.5em;
    text-transform: uppercase;
    border-bottom: 3px solid color(red);
    &.hide {
      //background-image: url('../images/icons/icon--arrow-black-up.png');
    }
    &.progress-disabled {
      color: color(text);
      opacity: 0.5;
    }
  }
  .ajax-progress {
    display: none;
  }
  .refrence-map-marker {
    font-size: 1.5em;
    color: color(corporate);
  }
}
.dms-references-map-form__map-preview {
  display: block;
  height: 20px;
  background: #d3d3d3 url('../images/reference-map-v2.png') repeat-x center center;
  background-size: auto;
  background-repeat: no-repeat;
}

// Services block
.block__views__services-block {
  margin-top: -3rem;
  margin-bottom: -3rem;
  padding: 3rem 0;
  background: #e7e5e5;
  .view-content {
    @extend %clearfix;
  }
  .views-row {
    float: left;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(2n + 1) {
      clear: left;
    }
    @include respond-to('xs') {
      &:nth-of-type(n) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

// News filter
.block__facetapi__news-tags {
  background: #EEE;
  padding: .5em 1em;
  ul.facetapi-facetapi-links {
    @extend %clearfix;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul.facetapi-facetapi-links li {
    float: left;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }
  a.facet__link {
    position: relative;
    display: block;
    padding: .5em .5em .5em 1.5em;
    color: #9a9a9a;
    text-decoration: none;
    &.facet__link--active {
     text-decoration: underline;
      color: color(text);
      &:hover,
      &:focus {
        color: #9a9a9a;
        text-decoration: underline;
      }
    }
    &:before {
      position: absolute;
      top: .5em;
      left: 0;
      content: '\f02c';
      font-family: 'FontAwesome';
    }
    &:hover,
    &:focus {
      color: color(text);
      text-decoration: none;
    }
  }
  .content {
    @extend %clearfix;
    margin-bottom: -.5rem;
  }
  a.reset {
    float: right;
    margin-bottom: .5rem;
    padding: 0;
  }
}

// GS: Social Media
.block__dms_general_settings__social_media {
  display: table-cell;
  vertical-align: middle;
  //float: left;
  font-size: 2.25em;
  .social-content {
    @extend %clearfix;
    padding: 0;
    margin: 0 0 -.5rem 0;
    list-style-type: none;
  }
  .social-content li {
    float: left;
    margin-right: .5rem;
    margin-bottom: .5rem;
    a,
    a:link,
    a:visited,
    a:active {
      color: color(corporate);
      text-decoration: none;
      &:hover,
      &:focus {
        color: color(corporate);
        text-decoration: none;
      }
    }
  }
  @include respond-to('m') {
    float: none;
    margin-bottom: .5rem;
  }
}

// Webform: Vacancy
.block__webform__vacancy {
  background: #EEE;
  padding: 1.5em;
  .content {

  }
}
.field-name-field-reference-images {
  margin-top: 3em;

  position: relative;
  z-index: 1;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);

  width: 100vw;
  .fa {
    font-size: 2em;
  }

  // new: flickty
  .is-selected {
    transform: scale(1.3333);
    z-index: 1;
  }
  .flickity-viewport {
    padding: 59px 0px;
    box-sizing: content-box;
  }
  // old: slick slider
  &.center .slick-center[aria-hidden="false"] {
    transform: scale(1.3333);
    z-index: 1;
  }
  .slick-list {
    padding: 59px 0px !important;
    height: 470px;
    @include respond-to('m') {
      height: 370px;
    }
    @include respond-to('xs') {
      height: 318px;
    }
  }
  .slick-slide {
    //margin: 0 25px;
    transition: transform 300ms ease;
  }



}
.field-reference-images__item__header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.field-reference-images__item__header__inner-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
.field-reference-images__item,
a.field-reference-images__item {
  position: relative;
  display: block;
  width: 500px;
  height: 352px;
  background-size: cover;
  background-position: center center;
  color: #FFF;
  transition: transform 300ms ease;
  @include respond-to('m') {
    height: 252px;
  }
  @include respond-to('xs') {
    height: 200px;
  }
}
.field-reference-images__item__header {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(142, 45, 52, 0.78);
  .css-icon {
    transform: scale(0.7777);
    margin-bottom: 1rem;
  }
}
.field-reference-images__item__header__inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.slick-center.field-reference-images__item:hover,
.slick-center.field-reference-images__item:focus {
  .field-reference-images__item__header {
    display: table;
    width: 100%;
    height: 100%;
  }
}

// Contact
.field-name-field-project-contact-type {
  .field-item {
    font-weight: bold;
    &:after {
      content: ':';
    }
  }
}
.field-collection-item-field-project-contact {
  .field {
    margin-top: 0;
  }
}
.field-collection-item-field-project-contact {
  margin-top: .5em;
}
.field-name-field-project-contact-type {
  text-transform: uppercase;
}
.field-name-field-project-contact-phone,
.field-name-field-project-contact--mail {
  .field-label {
    display: inline-block;
    font-weight: normal;
    text-transform: none;
  }
  .field-items {
    display: inline-block;
    a,
    a:link,
    a:visited,
    a:active {
      color: #FFF;
    }
  }
}

// Reference video
.field-name-field-project-video-url {
  a,
  a:link,
  a:visited,
  a:active {
    position: relative;
    display: block;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5) url(../images/icons/icon--play-button.png) no-repeat center center;
    }
  }
  img {
    display: block;
  }
}

//
.field-name-field-contact-phone,
.field-name-field-contact-fax {
  .field-label {
    display: inline-block;
    font-weight: normal;
  }
  .field-items {
    display: inline-block;
  }
}
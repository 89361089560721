// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Regular-Italic.eot');
  src: url('../fonts/Arial-Regular-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Arial-Regular-Italic.woff') format('woff'),
  url('../fonts/Arial-Regular-Italic.ttf') format('truetype'),
  url('../fonts/Arial-Regular-Italic.svg#arialregular') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Regular.eot');
  src: url('../fonts/Arial-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Arial-Regular.woff') format('woff'),
  url('../fonts/Arial-Regular.ttf') format('truetype'),
  url('../fonts/Arial-Regular.svg#arialregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Bold-Italic.eot');
  src: url('../fonts/Arial-Bold-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Arial-Bold-Italic.woff') format('woff'),
  url('../fonts/Arial-Bold-Italic.ttf') format('truetype'),
  url('../fonts/Arial-Bold-Italic.svg#arialregular') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Bold.eot');
  src: url('../fonts/Arial-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Arial-Bold.woff') format('woff'),
  url('../fonts/Arial-Bold.ttf') format('truetype'),
  url('../fonts/Arial-Bold.svg#arialregular') format('svg');
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family:"Dyno";
  src: url("../fonts/dyno_italic-webfont.eot?") format("eot"),
       url("../fonts/dyno_italic-webfont.woff") format("woff"),
       url("../fonts/dyno_italic-webfont.ttf") format("truetype"),
       url("../fonts/dyno_italic-webfont.svg#DynoRegular") format("svg");
  font-weight:normal;
  font-style:italic;
}

@font-face {
  font-family:"Dyno";
  src: url("../fonts/dyno_regular-webfont.eot?") format("eot"),
  url("../fonts/dyno_regular-webfont.woff") format("woff"),
  url("../fonts/dyno_regular-webfont.ttf") format("truetype"),
  url("../fonts/dyno_regular-webfont.svg#DynoRegular") format("svg");
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family:"Dyno";
  src: url("../fonts/dyno_bold_italic-webfont.eot?") format("eot"),
  url("../fonts/dyno_bold_italic-webfont.woff") format("woff"),
  url("../fonts/dyno_bold_italic-webfont.ttf") format("truetype"),
  url("../fonts/dyno_bold_italic-webfont.svg#DynoRegular") format("svg");
  font-weight:bold;
  font-style:italic;
}

@font-face {
  font-family:"Dyno";
  src: url("../fonts/dyno_bold-webfont.eot?") format("eot"),
  url("../fonts/dyno_bold-webfont.woff") format("woff"),
  url("../fonts/dyno_bold-webfont.ttf") format("truetype"),
  url("../fonts/dyno_bold-webfont.svg#DynoRegular") format("svg");
  font-weight:bold;
  font-style:normal;
}
// General
.paragraph-red {
  background: color(corporate);
  color: #FFF;
}
// Block
.paragraph-block {
  padding: 3rem 0;
}
.paragraph-block__header {
  margin-bottom: 1.75rem;
}

h2.paragraph-block__title {
  position: relative;
  margin: 0;
  padding-left: 1em;
  color: color(corporate);
  .fa {
    position: absolute;
    left: 0;
    top: 1em;
    font-size: 0.5em;
  }
}
.paragraph-block__body {
  margin-bottom: 1.75rem;
}

// Reference slider
.paragraph-reference-slider {
  padding: 3rem 0;
}
.paragraph-reference-slider__header {
  display: table;
  width: 100%;
  margin-bottom: 1.75rem;
  @include respond-to('xs') {
    display: block;
  }
}
.paragraph-reference-slider__title {
  display: table-cell;
  vertical-align: top;
  @include respond-to('xs') {
    display: block;
  }
}
.paragraph-reference-slider__cta {
  display: table-cell;
  vertical-align: top;
  text-align: right;
  padding-top: 9px;
  @include respond-to('xs') {
    display: block;
  }
  a,
  a:link,
  a:visited,
  a:active {
    display: inline-block;
    font-size: 0.75em;
    line-height: 1.25em;
    text-transform: uppercase;
    border-bottom: 2px solid #271919;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
h2.paragraph-reference-slider__title {
  position: relative;
  margin: 0;
  padding-left: 1em;
  color: color(corporate);
  .fa {
    position: absolute;
    left: 0;
    top: 1em;
    font-size: 0.5em;
  }
}
.paragraph-reference-slider__body {
  margin-bottom: 1.75rem;
}
.paragraph-reference-slider__slider {
  width: 100%;
  overflow: hidden;
  .layout-center {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  .center .slick-center[aria-hidden="false"] {
    transform: scale(1.3333);
    z-index: 1;
  }
  .slick-list {
    padding: 59px 0px !important;
    height: 470px;
    @include respond-to('m') {
      height: 370px;
    }
    @include respond-to('xs') {
      height: 320px;
    }
  }
  .slick-slide {
    //margin: 0 25px;
    transition: all 300ms ease;
  }
}

// Paragraph search
.paragraph-facet-search {
  position: relative;
  @extend %clearfix;
  background: #eeeeee;
  padding: .5em 0;
  transition: padding .35s ease;
  &.paragraph-facet-search--active {
    padding-top: 3em;
    padding-bottom: 3em;
  }
}
#facets-filters {
  position: absolute;
  width: 1px;
  background: transparent;
  height: 1px;
  display: block;
  top: -86px;
  z-index: -1;
  @include respond-to('m') {
    top: 0;
  }
}
.field-name-field-p-facet-search-block {
  @extend %clearfix;
  padding-top: 1.5rem;
}
.field-name-field-p-facet-search-block .field-collection-item-field-p-facet-search-block {
  //float: left;
  //width: 25%;
  //margin-right: 2%;
  &:last-child {
    //width: 46%;
    //margin-right: 0;
    .facetapi-facetapi-checkbox-links {
      //column-count: 2;
      //column-gap: .5em;
    }
  }
  ul.facetapi-facetapi-ajax-checkboxes li,
  .facetapi-facetapi-checkbox-links li {
    float: left;
    width: 25%;
  }
  &.clearfix {
    &:before,
    &:after {
      display: none;
    }
  }
}
.paragraph-facet-search__filters {
  position: relative;
  float: right;
  display: block;
  padding-right: 1em;
  text-transform: uppercase;
  cursor: pointer;
  .fa {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: .85em;
    .paragraph-facet-search--active &:before {
      content: '\f0d8';
    }
  }
}
.field-name-field-p-facet-search-block {
  clear: both;
  display: none;
  .paragraph-facet-search--active & {
    //display: block;
  }
}

// Image text
.paragraph-image-text {
  @extend %clearfix;
  padding: 3rem 0;
}
.field-name-field-p-image-text-image {
  .paragraph-image-text--ic-available & {
    float: left;
    width: 49%;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
  .paragraph-image-text--ic-available.paragraph-image-text--right & {
    float: right;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
  img {
    display: block;
    max-width: none;
    width: 100%;
  }
  a {
    display: block;
  }
}
@include respond-to('m') {
  .paragraph-image-text__content + .field-name-field-p-image-text-image,
  .field-name-field-p-image-text-image + .paragraph-image-text__content {
    margin-top: 1.5rem;
  }
}
.field-name-field-p-image-text-cta {
  .paragraph-image-text--ic-available & {
    float: left;
    width: 49%;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
  .paragraph-image-text--ic-available.paragraph-image-text--right & {
    float: right;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
}
.field-name-field-p-image-text-title {
  position: relative;
  color: color(corporate);
  padding-left: 1em;
  .paragraph-red & {
    color: #FFF;
  }

  .fa {
    position: absolute;
    left: 0;
    top: 1em;
    font-size: 0.5em;
  }
}
.paragraph-image-text__content {
  .paragraph-image-text--ic-available & {
    float: right;
    width: 49%;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
  .paragraph-image-text--ic-available.paragraph-image-text--right & {
    float: left;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
}

// Text centred
.paragraph-text-centered {
  @extend %clearfix;
  padding: 3rem 0;
  //text-align: center;
}
.field-name-field-p-text-centered-title {
  position: relative;
  color: color(corporate);
  .paragraph-red & {
    color: #FFF;
  }
  .fa {
    display: inline-block;
    font-size: 0.5em;
    vertical-align: middle;
    margin-right: 1em;
  }
}

// Image full width
.paragraph-full-width-image {
  @extend %clearfix;
  padding: 3rem 0;
  text-align: center;
}
.field-name-field-p-full-width-image-image {
  img {
    display: block;
  }
  a {
    display: block;
  }
}
.field-name-field-p-full-width-image-title {
  position: relative;
  color: color(corporate);
  .paragraph-red & {
    color: #FFF;
  }
  .fa {
    display: inline-block;
    font-size: 0.5em;
    vertical-align: middle;
    margin-right: 1em;
  }
}

.paragraph-full-width-image + .paragraph-text-centered {
  padding-top: 0;
}

// Slider
.paragraph-slider {
  @extend %clearfix;
  padding: 3rem 0;

  .paragraph-slider__header--left {
    text-align: left;
  }
  .paragraph-slider__header--center {
    text-align: center;
  }
  .paragraph-slider__header--right {
    text-align: right;
  }
  .slick-slide {
    margin: 0 1em;
  }
}
.field-name-field-p-slider-title {
  position: relative;
  color: color(corporate);
  .paragraph-red & {
    color: #FFF;
  }
  .fa {
    display: inline-block;
    font-size: 0.5em;
    vertical-align: middle;
    margin-right: 1em;
  }
}

// News
.paragraph__block__facetapi__50rlp2UMpRUaILpq0jVRZXUn1xAwz9XF {
  padding-bottom: 0;
}
.paragraph__block__views__news-search-api-block {
  padding-top: 0;
}

.paragraphs-item-timeline {
  padding: 3rem 0;

  h2.field-name-field-p-text-centered-title {
    margin-bottom: 5px;
  }

  .field-name-field-p-text-centered-body {
    margin-bottom: 15px;
  }
}

// Timeline & Timeline Item
.pg--timeline-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid color('grey');
  flex-direction: row;

  .field-name-field-date {
    padding: 5px 10px;
    background-color: color('red');
    color: color('white');
    text-align: center;
    min-width: 150px;
    margin-right: 25px;
    margin-bottom: 0;
  }

  @include respond-to('xs') {
    flex-direction: column;

    .field-name-field-date {
      margin-bottom: 10px;
    }
  }
}

// Partners & Partner
.paragraphs-item-partners {
  @extend .layout-center;
  .field-name-field-partners > .field-items {
    @include grid(4, '> .field-item', 2%);

    @include respond-to('l') {
      @include grid(3, '> .field-item', 2%, 4);
    }

    @include respond-to('m') {
      @include grid(2, '> .field-item', 2%, 3);
      text-align: left;
    }

    // @include respond-to('m') {
    //   @include grid(1, '> .field-item', 2%, 2);
    // }

    @include respond-to('xs') {
      @include grid(1, '> .field-item', 2%, 2);
      text-align: center;
    }
  }

  .field-name-field-contact  {
    font-size: 14px;
  }

  .field-name-field-logo {
    .field-item {
      display: flex;
      height: 150px;

      img {
        margin: auto;
      }
    }
  }

  .field-name-field-title {
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.paragraphs-item-partner {
  margin-bottom: 25px;
}

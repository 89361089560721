.sidebar-left--not-empty {
  .content-container {
    display: flex;
    flex-direction: row-reverse;
  }
}

.sidebar-left {
  @include respond-to('m') {
    display: none;
  }
}


// Paragraphg menu
.pg-menu {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  min-width: 250px;
  margin-left: 15px;
  top: 0;

  .header-navigation-absolute & {
    top: 80px;
  }
}
.pg-menu--list {
  list-style: none;
  padding: 3rem 0;

  li {
    line-height: 1;
    display: flex;
  }

  li:last-child {
    border-bottom: none;
  }

  a {
    text-decoration: none;
    background-color: #eee;
    line-height: 1.4;
    width: 100%;
    padding: 15px 20px;

    &:hover {
      text-decoration: underline;
      background-color: color('red');
      color: color('white');
    }
  }

  @include respond-to('m') {
    display: none;
  }
}

// General
.node__intro {
  @extend %clearfix;
  h1.page__title {
    margin: 0 0 .5em 0;
  }
}
.node__intro__content {

}
.node--field-body-right .node__intro__content {
  float: left;
  width: 46%;
  @include respond-to('m') {
    float: none;
    width: 100%;
  }
}
.node__intro .field-name-field-body-right {
  float: right;
  width: 46%;
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-top: 1.5rem;
  }
  img {
    display: block;
    max-width: none;
    width: 100%;
  }
  a.swipebox,
  a.swipebox:link,
  a.swipebox:visited,
  a.swipebox:active {
    position: relative;
    display: block;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.5) url('../images/icons/icon--play-button.png') no-repeat center center;
    }
  }
}



// Teaser updates
a.node--teaser-updates {
  display: block;
  text-decoration: none;
}
.node--teaser-updates__header {
}
h3.node--teaser-updates__type {
  color: #FFF;
  background: color(corporate);
  margin: 0;
  font-size: 1.125em;
  line-height: 1.25em;
  padding: .5em;
  text-align: center;
}
.node--teaser-updates__thumb {
  img {
    display: block;
    width: 100%;
    max-width: none;
  }
}
.node--teaser-updates__content {
  padding: 1em;
  border: 1px solid #dddddd;
  border-top: none;
  .field-name-body {
    font-size: 0.75em;
    line-height: 1.25em;
    margin-bottom: .5rem;
  }
}
.node--teaser-updates__created {
  text-transform: uppercase;
  font-size: 0.75em;
  line-height: 1.25em;
  margin-bottom: .5rem;
  color: rgba(color(text), 0.5)
}
h4.node--teaser-updates__title {
  font-size: 0.875em;
  line-height: 1.25em;
  margin: 0 0 .5rem 0;
}
.node--teaser-small__link,
.node--teaser-updates__link {
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.25em;
  text-transform: uppercase;
  border-bottom: 2px solid color(text);
}

// CTA
.node-cta {
  padding: 2em;
  background: color(corporate);
  color: #FFF;
  @include respond-to('m') {
    padding: 1em;
  }
  .block__dms_theme__cta_bottom & {
    display: table;
    width: 100%;
    padding: 3em 0;
    @include respond-to('m') {
      display: block;
    }
  }
  .field-name-body {
    font-size: 0.875em;
    .block__dms_theme__cta_bottom & {
      font-size: 1em;
    }
    a,
    a:link,
    a:visited,
    a:active {
      display: inline-block;
      color: #FFF;
    }
  }
  .field-name-field-cta-link {
    margin-top: 1rem;
    font-size: 0.75em;
    line-height: 1.25em;
    a,
    a:link,
    a:visited,
    a:active {
      display: inline-block;
      color: #FFF;
      border-bottom: 2px solid #FFF;
      text-decoration: none;
      text-transform: uppercase;
    }
    .block__dms_theme__cta_bottom & {
      font-size: 1em;
      a,
      a:link,
      a:visited,
      a:active {
        display: inline-block;
        padding: .5em 1em;
        font-size: 1.5em;
        color: color(text);
        background: #FFF;
        border: none;
        text-transform: none;
        white-space: nowrap;
      }
    }
  }
  .field-name-field-link-description {
    font-size: 0.75em;
    margin-top: .5em;
    .block__dms_theme__cta_bottom & {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
.node-cta__header {
  margin-bottom: 1rem;
}
h2.node-cta__title {
  position: relative;
  margin: 0;
  padding-left: 1em;
  color: #FFF;
  .block__dms_theme__cta_bottom & {
    font-size: 2em;
    line-height: 1.25em;
    padding-left: 0;
  }
  .fa {
    position: absolute;
    left: 0;
    top: 1em;
    font-size: 0.5em;
    .block__dms_theme__cta_bottom & {
      display: none;
    }
  }
}

.block__dms_theme__cta_bottom {
  .node-cta__header-body {
    display: table-cell;
    vertical-align: middle;
    @include respond-to('m') {
      display: block;
      width: 100%;
    }
  }
  .node-cta__link {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    @include typeface('button');
    @include respond-to('m') {
      display: block;
      width: 100%;
    }
  }
}

// Activity
.node-activity--teaser,
a.node-activity--teaser {
  position: relative;
  display: block;
  color: #FFF;
  text-decoration: none;
  font-size: 0.8125em;
  text-transform: uppercase;
  &:hover,
  &:focus {
    color: #FFF;
    text-decoration: none;
    .node-activity--teaser__header {
      background: rgba(142, 45, 52, 0.78);
    }
  }
  .field-name-field-activity-thumb {
    img {
      display: block;
      max-width: none;
      width: 100%;
    }
  }
}
.node-activity--teaser__header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.node-activity--teaser__header__inner-wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
.node-activity--teaser__title,
h3.node-activity--teaser__title {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
}

// Reference
.node-reference__project-details {
  position: relative;
  background: #eeeeee;
  margin-top: -60px;
  padding: 9em 0 12em 0;
  @include respond-to('m') {
    //margin-top: -111px;
    padding: 5em 0 9em 0;
  }
  @include respond-to('xs') {
    margin-top: -70px;
  }
  &:before,
  &:after {
    content: '';
    background: #EEE;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(50vw - 50%);
  }
  &:before {
    left: calc(-50vw + 50%);
  }
  &:after {
    right: calc(-50vw + 50%);
  }
  .field {
    margin-top: .5em;
    &:first-child {
      margin-top: 0;
    }
  }
  .field-label {
    text-transform: uppercase;
  }
}
.node-reference__project-details__title {
  color: color(corporate);
}
.node-reference__project-details__properties {
  float: left;
  width: 320px;
  margin-right: 2%;
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}
.node-reference__project-details__image {
  float: left;
  width: calc(98% - 320px);
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-top: 1.5rem;
  }
  a,
  a:link,
  a:visited,
  a:active {
    position: relative;
    display: block;
  }
  .video {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5) url(../images/icons/icon--play-button.png) no-repeat center center;
    }
  }
  img {
    display: block;
    max-width: none;
    width: 100%;
  }
}
.node-reference__project-description-details-extra-wrapper {
  position: relative;
  z-index: 1;
  display: table;
  width: 100%;
  border: 1px solid #dddddd;
  margin-top: -6em;
  @include respond-to('m') {
    display: block;
  }
}
.node-reference__project-description,
.node-reference__project-details-extra {
  display: table-cell;
  vertical-align: top;
  width: 50%;
  padding: 3em;
  @include respond-to('m') {
    display: block;
    width: 100%;
    padding: 1.5em;
  }
}
.node-reference__project-description {
  background: #FFF;
}
.node-reference__project-description__title {
  color: color(corporate);
}
.node-reference__project-details-extra {
  width: 50%;
  border-left: 1px solid #dddddd;
  color: #FFF;
  background: color(corporate);
  @include respond-to('m') {
    display: block;
    width: 100%;
    border-left: none;
  }
  .field {
    margin-top: .5em;
    &:first-child {
      margin-top: 0;
    }
  }
  .field-label {
    text-transform: uppercase;
  }
}
.node-reference__downloads {
  float: right;
  width: calc(50% - 1px);
  @include respond-to('m') {
    float: none;
    width: 100%;
  }
}
.node-reference__downloads__cta {
  display: block;
  color: color(text);
  background: #eeeeee url('../images/icons/icon--expand.png') no-repeat right 1em center;
  padding: .5em calc(2em + 30px) .5em 1em;
  text-decoration: none;
  text-align: right;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #FFF;
    background: color(corporate) url('../images/icons/icon--expand.png') no-repeat right 1em center;
    text-decoration: none;
  }
}
.node-reference__downloads__cta.node-reference__downloads__cta--expand {
  background-image: url('../images/icons/icon--contract.png');
  &:hover,
  &:focus {
    background-image: url('../images/icons/icon--contract.png');
  }
}
.node-reference__downloads__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.node-reference__downloads__item {
  text-align: right;
  a,
  a:link,
  a:visited,
  a:active {
    display: block;
    color: color(text);
    background: #eeeeee url('../images/icons/icon--download.png') no-repeat right 1em center;
    padding: .5em calc(2em + 30px) .5em 1em;
    text-decoration: none;
    &:hover,
    &:focus {
      color: #FFF;
      background: color(corporate) url('../images/icons/icon--download.png') no-repeat right 1em center;
      text-decoration: none;
    }
  }
}

.node-reference__project-images-private {
  margin-top: 1.5em;
  .slick-slide {
    margin: 0 1em;
  }
}

// Reference: Teaser
.node-reference--teaser,
a.node-reference--teaser {
  position: relative;
  display: block;
  width: 500px;
  height: 352px;
  background-size: cover;
  background-position: center center;
  color: #FFF;
  @include respond-to('m') {
    height: 252px;
  }
  @include respond-to('xs') {
    height: 200px;
  }
  &.node-reference--teaser-overview {
    height: 240px;
    width: auto;
  }
}
.node-reference--teaser__header {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(142, 45, 52, 0.78);
  @include respond-to('m') {
    display: table;
    width: 100%;
    top: auto;
    padding: .5em;
  }
  .css-icon {
    transform: scale(0.7777);
    margin-bottom: 1rem;
    @include respond-to('m') {
      display: none;
    }
  }
}
.node-reference--teaser__header__inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.slick-center.node-reference--teaser:hover,
.slick-center.node-reference--teaser:focus {
  .node-reference--teaser__header {
    display: table;
    width: 100%;
    height: 100%;
  }
}
.node-reference--teaser-overview:hover,
.node-reference--teaser-overview:focus {
  .node-reference--teaser__header {
    display: table;
    width: 100%;
    height: 100%;
  }
}
h3.node-reference--teaser__title {
  font-size: 1.125em;
  line-height: 1.25em;
  font-weight: normal;
  @include typeface('body');
  transform: scale(0.7777);
  margin: 0;
  .node-reference--teaser-overview & {
    transform: none;
  }
}
.node-reference--teaser__link {
  display: inline-block;
  font-size: .75em;
  line-height: 1.25em;
  border-bottom: 2px solid #FFF;
  text-transform: uppercase;
  transform: scale(0.7777);
  .node-reference--teaser-overview & {
    transform: none;
  }
}

// Vacancy
.node-vacancy__job-description {
  @extend %clearfix;
  margin-top: 1.75rem;
}
.field-name-field-vacancy-job-description {
  float: left;
  width: 49%;
  margin-right: 2%;
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  .field-item {
    margin-bottom: 1.75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.field-name-field-vjd-title {
  font-weight: bold;
  margin-bottom: .5rem;
}
.field-name-field-vjd-description {

}
.node-vacancy__job-description__webform {
  float: left;
  width: 49%;
  background: #EEE;
  padding: 1.5em;
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-top: 1.5rem;
  }
}

// Contact
.node-contact {
  @extend %clearfix;
  .node__intro__content {
    float: none;
    width: auto;
  }
  .node__intro {
    float: left;
    width: 49%;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }

}
.node-contact__webform {
  margin-top: 1.75rem;
  background: #EEE;
  padding: 1.5em;
}
.node-contact__contact-info {
  float: right;
  width: 49%;
  @include respond-to('m') {
    float: none;
    width: 100%;
    margin-top: 1.5rem;
  }
  h2 {
    position: relative;
    margin: 0 0 1rem 0;
    padding-left: 1em;
    .fa {
      position: absolute;
      left: 0;
      top: 1em;
      font-size: 0.5em;
    }
  }
}
.node-contact__contact-info__info {
  background: color(corporate);
  padding: 2em;
  color: #FFF;
  a,
  a:link,
  a:visited,
  a:active {
    color: #FFF;
    text-decoration: none;
    &:hover,
    &:focus {
      color: #FFF;
      text-decoration: underline;
    }
  }
}
.node-contact__contact-info__map {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .leaflet-control-container {
    display: none;
  }
}


// Node teaser
a.node-service--teaser,
.node-service--teaser {
  position: relative;
  display: block;
  text-decoration: none;
  color: #FFF;
  &:hover,
  &:focus {
    color: #FFF;
    .node-service--teaser__header {
      background-color: rgba(color(corporate), 0.78);
    }
  }
  img {
    display: block;
  }
}

.node-service--teaser__header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.node-service--teaser__header--inner-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  h3 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
  }
}


// Node teaser small
a.node--teaser-small,
.node--teaser-small  {
  display: block;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  .field-name-body {
    padding: 1em 1em 0 1em;
  }
}
.node--teaser-small__header {
  background: #EEE;
  padding: .5em;
}
h2.node--teaser-small__title {
  margin: 0;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.node--teaser-small__link {
  margin: 1em 1em 0 1em;
}

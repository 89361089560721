.user-profile-form {
  margin-top: 1.5rem;
  .password-strength {
    display: none !important;
  }
  .confirm-parent,
  .password-parent {
    width: 100%;
  }
  div.password-confirm {
    display: none;
  }
  .form-type-password + .form-type-password {
    margin-top: 1.5rem;
  }
}
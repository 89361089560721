// .sliding-popup-bottom {
//   width: 100%;
//   background: #8e2d34;
//   color: #FFF;
//   padding: 1rem 0;
// }
// #sliding-popup .popup-content {
//   max-width: 1040px;
//   padding-left: 20px;
//   padding-right: 20px;
// }

// #sliding-popup .popup-content #popup-text {
//   @media screen and (max-width: 600px) {
//     float: none;
//   }
// }
// #sliding-popup .popup-content #popup-buttons {
//   margin-bottom: -.5rem;
//   text-align: right;

//   @media screen and (max-width: 600px) {
//     text-align: center;
//     margin-top: 1rem;
//   }
// }
// #sliding-popup .popup-content #popup-buttons button {
//   display: inline-block;
//   margin-left: .5rem;
//   margin-bottom: .5rem;
//   &:first-child {
//     margin-right: 0;
//   }
//   padding: .5em 1.5em;
//   color: #FFF;
//   text-decoration: none;
//   border: none;
//   color: color(button-bg);
//   background: #FFF;
//   &:hover,
//   &:focus {
//     color: color(button-bg);
//     background: #FFF;
//     text-decoration: none;
//   }
// }


.sliding-popup-bottom{
  width: calc(100% - 80px);
  max-width: 750px;
  padding: 50px;
  color: #ffffff;
  background: lighten(color('red'), 8%);
  position: fixed;
  right: 40px;
  bottom: 40px !important;
  text-align: left !important;
  line-height: 1.5em;
  box-shadow: rgba(#000, 0.1) 0 0 10px;
  // @include box-sizing(border-box);

  h2, h3, a{
    color: #ffffff;
  }

  h2, h3, {
    margin: 0 0 20px 0;
  }

  p{
    margin: 0 0 20px 0;
    
    &:nth-last-child(1){
      margin: 0;
    }
  }
  @include respond-to('l') {
  // @include breakpoint($desktop){
    width: calc(100% - 60px);
    padding: 30px;
    right: 30px;
    bottom: 30px !important;
  }
  @include respond-to('xs') {
  // @include breakpoint($noglobal){
    width: 100%;
    height: 100%;
    padding: 30px 0;
    right: 0;
    bottom: 0 !important;
  }

  .inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    -webkit-overflow-scrolling: touch;

    @include respond-to('xs') {
    // @include breakpoint($noglobal){
      display: block;
      height: 100%;
      padding: 0 30px;
      overflow-y: auto;
    }

    .gdpr_popup__content {
      flex: 1 1 auto;

      .gdpr_popup__content__intro {
        margin: 0 0 30px 0;
      }

      .gdpr_popup__content__categories {
        clear: both;
        display: none;

        .category {
          margin: 0 0 15px 0;

          &:nth-last-child(1) {
            margin: 0;
          }

          .category__form-element {
            margin: 0 0 5px 0;

            input{
              display: none;
            }

            label {
              padding: 0 0 0 25px;
              position: relative;
              color: #ffffff;
              cursor: pointer;
              line-height: 1.3em;

              &::before {
                width: 14px;
                height: 14px;
                content: '';
                border: 1px solid #ffffff;
                background-color: #ffffff;
                background-size: 10px 10px;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 3px;
                position: absolute;
                left: 0;
                top: 3px;
                // font-family: 'dmsicons';
                font-size: 0.6rem;
                line-height: 0.8rem;
                text-align: center;
              }
            }

            input:checked + label {
              &::before {
                background-image: url('../images/svg/check.svg');
              }
            }

            input:disabled {
              & + label {
                color: #ffffff;
                cursor: default;


                &::before{
                  color: #ffffff;
                  background-color: lighten(color('red'), 15%);
                  background-image: url('../images/svg/check-white.svg');
                  border: 1px solid lighten(color('red'), 15%);
                }
              }
            }
          }

          .category__description {
            padding-left: 25px;
            color: rgba(color('white'), .75);
            font-size: 0.9rem;


            @include respond-to('xs') {
            // @include breakpoint($mobile){
              display: none;
            }
          }
        }
      }

      .gdpr_popup__content__buttons {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 30px 0 0 0;

        @include respond-to('xs') {
        // @include breakpoint($noglobal){
          display: block;
        }

        .gdpr-action-link {
          cursor: pointer;
          text-decoration: underline;

          @include respond-to('xs') {
          // @include breakpoint($noglobal){
            display: block;
            margin: 0;
          }

          &:hover {
            text-decoration: none;
          }

          &.manage-cookies--link {

          }
        }

        .save-preferences--link--container {
          display: none;
        }

        .agree-button {
          margin-right: 20px;

          @include respond-to('xs') {
          // @include breakpoint($noglobal){
            display: block;
            margin: 0 0 20px 0;
          }

          &:hover, &:focus{
            color: #FFF !important;
          }
        }
      }

      .gdpr_popup__privacy-url{
        margin: 30px 0 0 0;

        a{
          color: #ffffff;
          text-decoration: underline;

          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
}
// Dependencies.
@import 'components/clearfix/clearfix';

// Form item
//
// Wrapper for a form element (or group of form elements) and its label.
//
// .form-item--inline - Inline form items.
// .form-item--tight  - Packs groups of form items closer together.
// .is-error          - Highlight the form elements that caused a form
//                      submission error.
//
// Markup: form-item.twig
//
// Style guide: forms.form-item

// Form item (radio)
//
// Checkboxes and radios require slightly different markup; their label is after
// their widget instead of before. Uses the `.form-item--radio` class variant of
// the normal form item and is placed on each of the nested form items.
//
// .is-error - Highlight the form elements that caused a form submission error.
//
// Markup: form-item--radio.twig
//
// Style guide: forms.form-item--radio

.form-item,
%form-item {
  @include margin(1 0);

  // The part of the label that indicates a required field.
  &__required {
    color: color(form-error);
  }

  // The descriptive help text (separate from the label).
  &__description {
    @include font-size(s);
  }

  &--inline {
    div,
    label {
      display: inline;
    }

    // There's always an exception.
    &__exception {
      display: block;
    }
  }

  &--tight {
    margin: 0;
  }

  &--radio {
    @extend %form-item--tight;
  }

  &--radio &__label,
  &--radio__label {
    display: inline;
    font-weight: normal;
  }

  &--radio &__description,
  &--radio__description {
    margin-left: 1.4em;
  }

  &.is-error &__widget,
  &--is-error__widget {
    border: 1px solid color(form-error);
  }

  &.is-error,
  &--is-error {
    input,
    textarea,
    select {
      @extend %form-item--is-error__widget;
    }
  }
}

.form-actions {
  @include margin(1 0);
}
form {
  @include margin(-1 0);
}
//
// Drupal selectors.
//

.form-item {
  input.error,
  textarea.error,
  select.error {
    @extend %form-item--is-error__widget;
  }

  .description {
    @extend %form-item__description;
  }
}

.form-required {
  @extend %form-item__required;
}

// Password confirmation.
.password-parent,
.confirm-parent {
  @extend %form-item--tight;
}

table {
  .form-item {
    @extend %form-item--tight;
  }
}

.form-type-radio,
.form-type-checkbox {
  @extend %form-item--radio;

  .description {
    @extend %form-item--radio__description;
  }
}

label.option {
  @extend %form-item--radio__label;
}

.container-inline {
  .form-item {
    @extend %form-item--inline;
  }

  // Fieldset contents always need to be rendered as block.
  .fieldset-wrapper {
    @extend %form-item--inline__exception;
  }
}

.form-type-email,
.webform-component-email,
.form-type-textfield,
.webform-component-textfield,
.form-type-textarea,
.webform-component-textarea,
.form-type-password {
  position: relative;

  input,
  textarea {
    position: relative;
    width: 100%;
    border: none;
    border-bottom: 2px solid transparent;
    padding: calc(.5rem - 1px) 1rem;
    box-shadow: 0px 0px 0px 1px #EEE;
    border-radius: 0;
    background: #EEE;
    .webform-client-form & {
      background-color: #FFF;
    }
    &:focus {
      outline: none;
      border-bottom: 2px solid color(corporate);
    }
    &.error {
      background: #fff0f0;
      border-bottom: 2px solid color(form-error);
    }
  }

  input {
    height: calc(28px + 1rem);
  }

  textarea {
    display: block;
    resize: none;
  }

  label {
    color: rgba(#000, 0.5);
    font-weight: normal;
  }
}
label {
  color: rgba(#000, 0.5);
  font-weight: normal;
}
.form-type-select {
  position: relative;
  label {
    font-size: 0.75em;
    color: #000;
  }

  select {
    position: relative;
    width: 100%;
    border: none;
    border-bottom: 2px solid transparent;
    height: 44px;
    padding: 0 2.5em 0 1em;
    box-shadow: 0px 0px 0px 1px #EEE;
    border-radius: 0;
    appearance: none;
    background: #EEE url('../images-source/icons/icon--caret-down.png') no-repeat right 1em center;
    .webform-client-form & {
      background-color: #FFF;
    }
    &:focus {
      outline: none;
      border-bottom: 2px solid #000;
    }
    &.error {
      background: #fff0f0;
      border-bottom: 2px solid color(form-error);
    }
  }
}

input[type=file] {
  background: #EEE;
  color: color(text);
  padding: .35em;
  width: calc(100% - 96px);
  & + input[type="submit"] {
    color: #FFF;
    width: 96px;
    background: color(button-bg);
    padding: .5em 1.5em;
    &:hover,
    &:focus {
      background: color(button-bg)
    }
  }
  .webform-client-form & {
    background-color: #FFF;
  }
}
.form-submit {
  display: inline-block;
  padding: .5em 1.5em;
  color: #FFF;
  text-decoration: none;
  border: none;
  background: color(button-bg);
  &:hover,
  &:focus {
    color: #FFF;
    background: color(button-bg);
    text-decoration: none;
  }
  .webform-client-form .form-actions & {
    display: block;
    width: 100%;
  }
}

.dms-references-map-form {
  margin: 0;
  .form-actions {
    margin: .5rem 0 0 0;
  }
  .form-submit {
    color: color(text);
  }
}


// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.
//
// Markup: navbar.twig
//
// Style guide: navigation.navbar

// Top menu & User menu
.block__menu_block__menu-top-menu,
.block__menu_block__user-menu {
  .header__navigation & {
    float: left;
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .menu li {
      float: left;
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      display: block;
      font-size: 0.75em;
      padding: .5em 1em;
      color: rgba(16,0,0,0.8);
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  .region-footer & {
    float: left;
    width: calc(33.3333% - 70px - 4rem);
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .menu li {
      @extend %clearfix;
      margin-bottom: .25rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      float: left;
      display: block;
      font-size: 0.75em;
      color: rgba(16,0,0,0.8);
      text-decoration: none;
      border-bottom: 3px solid color(corporate);
      text-transform: uppercase;
      &:hover,
      &:focus {
        border-color: rgba(16,0,0,0.8);
        text-decoration: none;
      }
    }
    @include respond-to('l') {
      width: calc(33.3333% - 1rem);
      margin-top: 1.5rem;
      margin-right: 0;
    }
    @include respond-to('xs') {
      float: none;
      width: auto;
    }
  }
  .region-footer .block--main-menu--top-menu--wrapper & {
    float: none;
    width: 100%;
    margin-top: 1.5rem;
  }
  .region-mobile-menu & {
    float: none;
    .menu li {
      float: none;
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      color: #FFF;
      &:hover,
      &:focus {
        color: #FFF;
      }
    }
  }
}

// Language
.block__lang_dropdown__language {
  float: left;
  margin-left: .5rem;
  @include respond-to('m') {
    float: right;
  }
  .fa {
    margin-right: -2em;
    color: rgba(16,0,0,0.8);
  }
  .lang_dropdown_form {
    font-size: 0.75em;
    margin-right: -1.5em;
    select {
      border: none;
      background: transparent;
      color: rgba(16,0,0,0.8);
      appearance: none;
      border-radius: 0;
      width: auto !important;
      height: auto;
      box-shadow: none;
      text-decoration: underline;
      text-transform: uppercase;
      padding: .5em 1em .5em 2em;
      cursor: pointer;
      &:focus,
      &:hover {
        border-bottom: none;
      }
    }
  }
  form {
    margin: 0;
  }
}


// Main menu
.block__menu_block__main-menu {
  .header__navigation & {
    float: right;
    .menu {
      @extend %clearfix;
      list-style-type: none;
      margin: 0 -2em 0 0;
      padding: 0;
      font-size: 0.8125em;
      @include respond-to('m') {
        margin: 0;
      }
    }
    .menu li {
      float: left;
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      display: block;
      padding: 2em 2em;
      color: rgba(16,0,0,0.8);
      text-decoration: none;
      text-transform: uppercase;
      transition: padding .35s ease;
      @include respond-to('l') {
        padding: 2em 1em;
      }
      .sticky & {
        padding: 1em 2em;
        @include respond-to('m') {
          padding: .5em 1em;
        }
      }
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  .region-footer & {
    float: left;
    width: calc(33.3333% - 70px - 4rem);
    margin-right: 2rem;
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .menu li {
      @extend %clearfix;
      margin-bottom: .25rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      float: left;
      display: block;
      font-size: 0.75em;
      color: rgba(16,0,0,0.8);
      text-decoration: none;
      border-bottom: 3px solid color(corporate);
      text-transform: uppercase;
      &:hover,
      &:focus {
        border-color: rgba(16,0,0,0.8);
        text-decoration: none;
      }
    }
    @include respond-to('l') {
      width: calc(33.3333% - 1rem);
      margin-right: 1.5rem;
      margin-top: 1.5rem;
    }
    @include respond-to('xs') {
      float: none;
      width: auto;
      margin-top: 1.5rem;
    }
  }
  .region-footer .block--main-menu--top-menu--wrapper & {
    float: none;
    width: 100%;
  }
  .region-mobile-menu & {
    float: none;
    background: #FFF;
    .menu li {
      float: none;
    }
    .menu a,
    .menu a:link,
    .menu a:visited,
    .menu a:active {
      padding: .5em 1em;
      background: #FFF;
      &:hover,
      &:focus {
      }
    }
  }
}

// Bottom menu
.block__menu__menu-bottom-menu {
  display: table-cell;
  vertical-align: middle;
  @include respond-to('xs') {
    display: block;
    float: none;
  }
  .content {
    float: right;
  }
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .menu li {
    float: left;
    font-size: 0.75em;
    color: color('red');
    &:after {
      display: inline-block;
      content: '|';
      padding: 0 .5rem;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    @include respond-to('xs') {
      float: none;
      &:after {
        display: none;
      }
    }
  }
  .menu a,
  .menu a:link,
  .menu a:visited,
  .menu a:active {
    float: left;
    display: block;
    // color: rgba(16,0,0,0.2);
    color: color('red');
    text-decoration: none;
    &:hover,
    &:focus {
      // color: rgba(16,0,0,0.2);
      color: color('red');
      text-decoration: underline;
    }
    @include respond-to('xs') {
      float: none;
    }
  }
}


// Menu wrapper
.block--main-menu--top-menu--wrapper {
  float: left;
  width: calc(55% - 70px - 7rem);
}

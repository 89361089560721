// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  @extend %clearfix;
  width: 100%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;

  .header-navigation-absolute & {
    position: fixed;
    @include respond-to('m') {
      position: absolute;
    }
  }
  body.adminimal-menu.header-navigation-absolute & {
    top: 29px;
  }

  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.5);

  // Wrapping link for logo.
  &__logo {
    float: left;
    width: 178px;
    margin-right: 1.5rem;
    padding: 20px;
    transition: padding .35s ease;
    @include respond-to('m') {
      width: auto;
    }
  }
  &.sticky &__logo {
    padding: 15px 20px;
    @include respond-to('m') {
      padding: 20px;
    }
  }

  // Logo image.
  &__logo-image {
    display: block;
    width: 138px;
    height: 112px;
    vertical-align: bottom;
    transition: width .35s ease, height .35s ease;
    @include respond-to('m') {
      width: 69px;
      height: 56px;
    }
  }
  &.sticky &__logo-image {
    width: 69px;
    height: 56px;
  }

  &__navigation {
    float: right;
    width: calc(100% - 178px - 1.5rem);
    @include respond-to('m') {
      width: calc(100% - 119px - 1.5rem);
    }
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}

.pager-load-more {
  margin: 1.5rem 0 0 0;
  font-size: .85em;
  a,
  a:link,
  a:visited,
  a:active {
    border-bottom: 3px solid color(text);
    text-decoration: none;
    text-transform: uppercase;
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &.progress-disabled {
      opacity: 0.5;
      cursor: pointer;
    }

  }
  .ajax-progress {
    display: none;
  }
}

// Activities (front)
.block__views__activities-block {
  .view-content {
    @extend %clearfix;
  }
  .views-row {
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n + 1) {
      clear: left;
    }
    @include respond-to('l') {
      &:nth-of-type(n) {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
        clear: none;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        clear: left;
      }
    }
    @include respond-to('xs') {
      &:nth-of-type(n) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

// Related references
.block__views__related_reference-block,
.block__views__news_search_api-block,
.block__views__news-block {
  margin-top: 1.75rem;
  h2.block__title {
    color: color(corporate);
  }
  .view-content {
    @extend %clearfix;
    margin-bottom: -2%;
  }
  .views-row {
    float: left;
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n + 1) {
      clear: left;
    }
    @include respond-to('l') {
      &:nth-of-type(n) {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 2%;
        clear: none;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        clear: left;
      }
    }
    @include respond-to('xs') {
      &:nth-of-type(n) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
.block__views__news-block,
.block__views__vacancies-block {
  margin-top: 0;
}

// Vacancies
.block__views__vacancies-block {
  .views-row {
    margin-top: 1.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
}

// References
.block__views__references-block {
  .view-content {
    @extend %clearfix;
  }
  .views-row {
    float: left;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 2%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(2n + 1) {
      clear: left;
    }
    @include respond-to('xs') {
      &:nth-of-type(n) {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}


// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Breadcrumb navigation
//
// The path to the current page in the form of a list of links.
//
// Markup: breadcrumb.twig
//
// Style guide: navigation.breadcrumb

.breadcrumb,
%breadcrumb {
  @media print {
    display: none;
  }

  padding: .5rem 0;

  &__title {
    @extend %visually-hidden;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: color(corporate);
    font-size: 0.875em;
    &:last-child {
      text-decoration: underline;
    }
  }

  &__separator {
    color: color(text);
    padding: 0 .5em;
  }

  a,
  a:link,
  a:visited,
  a:active {
    color: color(text);
    text-decoration: underline;
    &:hover,
    &:focus {
      color: color(text);
      text-decoration: none;
    }
  }

}

// Should be moved in base.
// Paragraphs
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0;
}

h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1.page__title {
  color: color(corporate);
}

ul:first-child {
  margin-top: 0;
}
ul:last-child {
  margin-bottom: 0;
}

a,
a:link,
a:visited,
a:active {
  color: color(text);
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

body.adminimal-menu.header-navigation-absolute {
  position: relative;
  //top: 29px;
}

// Main
.node-type-reference .main {
  overflow: hidden;
}
.main-content {
  margin-bottom: 1.75rem;
  .page-user & {
      @extend %clearfix;
  }
}

blockquote {
  margin: 1.5rem 0;
  padding: 1em 0 1em 1.5em;
  color: color('corporate');
  border-left: 3px solid color('corporate');
  font-size: 1.1em;
  font-style: italic;
}


//LINK

//Regions
// Regions: Top navigation
.region-top-navigation {
  position: relative;
  @extend %clearfix;
  background: #EEE;
  padding: 0 20px;
  overflow: hidden;
  clip-path: url("#clip-polygon");
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 1% 100%);
  .triangle {
    display: none;
    /*
    position: absolute;
    background: #FFF;
    width: 40px;
    height: 200%;
    left: -20px;
    transform: rotate(-23deg);
    top: -10px;*/
  }
  @include respond-to('m') {
    display: none;
  }
}
.clip-svg {
  width: 0;
  height: 0;
}
.region-top-navigation__inner-wrapper {
  float: right;
}

// Regions: Navigation
.region-navigation {
  @extend %clearfix;
  padding: 22px 20px;
  overflow: hidden;
  transition: padding .35s ease;
  .sticky & {
    padding: 0px 20px;
    @include respond-to('m') {
      padding: 30px 20px;
    }
  }
  @include respond-to('l') {
    font-size: .9em;
    padding: 30px 20px;
  }
}

// Regions: Sub content
.sub-content-wrapper--sidebar {
  @extend %clearfix;
  .region-sub-content {
    float: left;
    width: 49%;
    @include respond-to('m') {
      float: none;
      width: 100%;
    }
  }
  .region-sub-content-sidebar {
    float: right;
    width: 49%;
    padding-top: 3em;
    margin-bottom: 3rem;
    @include respond-to('m') {
      float: none;
      width: 100%;
      padding-top: 0;
      margin-top: 0;
    }
  }
  .layout-center  {
    padding: 0;
  }
}

// Regions: footer
.region-footer {
  @extend %clearfix;
  padding: 5rem 0;
  background: #e7e5e5;
}

// Regions: Bottom
.region-bottom {
  @extend %clearfix;
  padding: 1.5rem 0;
  .layout-center {
    display: table;
    width: 100%;
  }
}

// Regions: Mobile menu
.region-mobile-menu {
  display: none;
  position: absolute;
  top: 98px;
  right: 0;
  left: 0;
  z-index: 10;
  background: color(red);
}

// Css icons
.css-icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: top;
}
.css-icon--plus:before,
.css-icon--plus:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #FFF;
}
.css-icon--plus:before {
  transform: rotate(90deg);
}

//Facetapi
.facetapi-facetapi-checkbox-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  input {
    display: none;
  }
  li {
    margin-bottom: .5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.facetapi-disabled {
  opacity: 1;
  filter: alpha(opacity = 100);
}

a.facetapi-checkbox,
a.facetapi-checkbox:link,
a.facetapi-checkbox:visited,
a.facetapi-checkboxa:active {
  text-decoration: none;
  background: transparent url('../images/icons/checkbox.png') no-repeat left top;
  padding-left: 1.75em;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &.facetapi-active {
    background: transparent url('../images/icons/checkbox-active.png') no-repeat left top;
  }
}

.facetapi-checkbox.facetapi-active {
  display: inline-block !important;
}

.facetapi-facetapi-ajax-checkboxes {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .form-type-checkbox input {
    display: none;
  }
  .form-type-checkbox label {
    display: inline-block;
    text-decoration: none;
    background: transparent url('../images/icons/checkbox.png') no-repeat left top;
    padding-left: 1.75em;
    color: color(text);
    &:hover,
    &:focus {
      text-decoration: none;
    }
    //&.facetapi-active {
    //  background: transparent url('../images/icons/checkbox-active.png') no-repeat left top;
    //}
  }
  .form-type-checkbox input:checked + label {
    background: transparent url('../images/icons/checkbox-active.png') no-repeat left top;
  }
  .form-type-checkbox.form-disabled {
    opacity: 0.5;

  }
}

// NO transition
.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.leaflet-touch .leaflet-bar a {
  text-decoration: none;
}
.leaflet-bottom.leaflet-right {
  display: none;
}

// Messags


//Video
.field--video-url {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5) url(../images/icons/icon--play-button.png) no-repeat center center;
  }
}

//
.mobile-menu-toggle {
  float: right;
  display: block;
  background: color(red);
  padding: .5em;
  color: #FFF;
  .fa {
    vertical-align: middle;
    margin-right: .5em;
  }
  &.active {
    .fa-angle-down:before {
      content: '\f106';
    }
  }
}
.btn,
a.btn,
a.btn:link,
a.btn:active,
a.btn:visited {
  display: inline-block;
  padding: .5em 1em;
  cursor: pointer;
  text-decoration: none;
  @include typeface('button');
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
/*
.btn--pink,
a.btn--pink,
a.btn--pink:link,
a.btn--pink:active,
a.btn--pink:visited {
  background: color(btn-pink);
  color: color(btn-pink-text);
  &:focus,
  &:hover {
    background: color(btn-pink);
  }
}

.btn--black,
a.btn--black,
a.btn--black:link,
a.btn--black:active,
a.btn--black:visited  {
  background: color(btn-black);
  color: color(white);
  &:focus,
  &:hover {
    color: color(white);
    background: color(btn-black);
  }
}

.btn--white,
a.btn--white,
a.btn--white:link,
a.btn--white:active,
a.btn--white:visited  {
  background: color(btn-white);
  color: color(text);
  &:focus,
  &:hover {
    color: color(text);
    background: color(btn-white);
  }
}

.btn--grey,
a.btn--grey,
a.btn--grey:link,
a.btn--grey:active,
a.btn--grey:visited  {
  background: color(btn-grey);
  color: color(text);
  &:focus,
  &:hover {
    color: color(text);
    background: color(btn-grey);
  }
}

.btn--black-border,
a.btn--black-border,
a.btn--black-border:link,
a.btn--black-border:active,
a.btn--black-border:visited  {
  border: 2px solid color(btn-black);
  color: color(btn-black);
  padding: calc(.5em - 2px) calc(1em - 2px); // Keep buttons the same size
}

.btn--uppercase {
  text-transform: uppercase;
}

.btn--text-light,
a.btn--text-light,
a.btn--text-light:link,
a.btn--text-light:active,
a.btn--text-light:visited  {
  color: #888888;
}


.btn--round-grey,
a.btn--round-grey,
a.btn--round-grey:link,
a.btn--round-grey:active,
a.btn--round-grey:visited {
  display: inline-block;
  padding: .25em .5em;
  cursor: pointer;
  text-decoration: none;
  background: color(btn-round-grey);
  border-radius: 5px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}


.btn--round-pink,
a.btn--round-pink,
a.btn--round-pink:link,
a.btn--round-pink:active,
a.btn--round-pink:visited {
  display: inline-block;
  padding: .25em .5em;
  cursor: pointer;
  text-decoration: none;
  background: color(btn-pink);
  color: color(btn-pink-text);
  border-radius: 5px;
  &:hover,
  &:focus {
    background: color(btn-pink);
    color: color(btn-pink-text);
    text-decoration: underline;
  }
}

.btn--icon-left,
a.btn--icon-left,
a.btn--icon-left:link,
a.btn--icon-left:active,
a.btn--icon-left:visited {
  .fa,
  .icon {
    margin-right: .5em;
  }
}

.btn--icon-right,
a.btn--icon-right,
a.btn--icon-right:link,
a.btn--icon-right:active,
a.btn--icon-right:visited {
  .fa,
  .icon {
    margin-left: .5em;
  }
}*/